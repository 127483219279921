window.addEventListener('load', () => {
    const cookiebanner = document.querySelector('#cookiebanner');
    const cookieSpacer = document.querySelector('#cookieSpacer');
    if (cookiebanner) {

        cookieSpacer.after(cookiebanner);
        cookiebanner.classList.remove("hide");

        window.addEventListener('CookiebotOnDecline', (e) => {

        }, false);
        window.addEventListener('CookiebotOnAccept', (e) => {

        }, false);
    } else {
        cookieSpacer.classList.add("hide");
    }

});
